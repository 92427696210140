import { WppPill, WppTooltip } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Pill } from 'api/assistant/fetchers/pills/fetchPills'
import { useFetchPills } from 'api/assistant/queries/pills/useFetchPills'
import { useFetchUserSettings } from 'api/assistant/queries/userSettings/useFetchUserSettings'
import styles from 'components/assistant/chat/chatInstructions/ChatInstructions.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { HELKEL_TENANT_ID, VMLY_HULK_TENANT_ID } from 'overrides/tenantIds'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

import { replacePlaceholders } from './chatInstructionsMockData'
import { tooltipConfig } from '../../../../utils/tooltipConfig'
import { OpenAIAnswer } from '../chatBubble/openAIAnswer/OpenAIAnswer'

interface Props {
  question: string
  onPromptClick: (prompt: string) => void
}

export const ChatInstructions = ({ question, onPromptClick }: Props) => {
  const { osContext } = useOs()
  const { t } = useTranslation()

  const { data: userSettingsAPI } = useFetchUserSettings({
    params: { userId: osContext.userDetails.id },
  })

  const { data: pillsData } = useFetchPills({
    params: {
      tenantId: osContext.tenant.id,
      fullpageAppId: osContext.appInstance?.devhubAppId,
      isMention: userSettingsAPI.mentionsEnabled ? null : false,
    },
  })

  const [activePill, setActivePill] = useState('')
  const [activePillQuestion, setActivePillQuestion] = useState('')

  // Functions handling the replacement of placeholders in prompts
  const getClientName = useMemo(() => {
    if (osContext?.workspace) {
      for (let key in osContext?.workspace.mapping) {
        if (osContext?.workspace.mapping[key].type === 'CLIENT') {
          return osContext?.workspace.mapping[key].name
        }
      }
    }
    return null
  }, [osContext?.workspace])

  const getBrandName = useMemo(() => {
    if (osContext?.workspace) {
      for (let key in osContext?.workspace.mapping) {
        if (osContext?.workspace.mapping[key].type === 'BRAND') {
          return osContext?.workspace.mapping[key].name
        }
      }
    }
    return null
  }, [osContext?.workspace])

  const getMarketName = useMemo(() => {
    if (osContext?.workspace) {
      for (let key in osContext?.workspace.mapping) {
        if (osContext?.workspace.mapping[key].type === 'MARKET') {
          return osContext?.workspace.mapping[key].name
        }
      }
    }
    return null
  }, [osContext?.workspace])

  // Replaceable data
  const mentionsDataReplacements = useMemo(() => {
    return {
      '[project]': osContext?.project?.name ? `[${osContext?.project?.name}]` : '[project]',
      '[client]': getClientName ? getClientName : '[client]',
      '[brand]': getBrandName ? getBrandName : '[brand]',
      '[market]': getMarketName ? getMarketName : '[market]',
      '[tenant]': osContext?.tenant?.name ? `[${osContext?.tenant?.name}]` : '[tenant]',
    } as any
  }, [getBrandName, getClientName, getMarketName, osContext?.project?.name, osContext?.tenant?.name])

  const activePrompts = useMemo(() => {
    // if (BAT_PROD_ID === osContext.tenant.id) {
    //   return PromptsDataCustomClient
    // }
    return userSettingsAPI.mentionsEnabled
      ? replacePlaceholders(pillsData, mentionsDataReplacements)
      : replacePlaceholders(pillsData, mentionsDataReplacements)
  }, [mentionsDataReplacements, pillsData, userSettingsAPI.mentionsEnabled])

  useEffect(() => {
    const activePillQuestionWithoutBrackets = activePillQuestion.replace(/[[\]]/g, '')
    if (activePillQuestionWithoutBrackets !== question) {
      setActivePill('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question])

  const handlePromptClick = (prompt: Pill) => {
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: {
        action: EVENTS.ACTIONS.PILL_CLICK,
        params: [{ key: 'pill_text', value: prompt.content }],
      },
    })

    setActivePillQuestion(prompt.content)
    setActivePill(prompt.id)
    onPromptClick(prompt.content)
  }

  return (
    <div className={styles.instructions}>
      <div className={clsx(styles.selectable, 'cancel-drag')}>
        <OpenAIAnswer text={t('how_to_use.welcome_message')} />
      </div>

      {osContext.tenant.id !== HELKEL_TENANT_ID && osContext.tenant.id !== VMLY_HULK_TENANT_ID && (
        <Flex wrap="wrap" gap={8}>
          {activePrompts.map((prompt: Pill, index: number) => {
            return prompt.tooltip ? (
              <WppTooltip
                key={index}
                className={clsx(styles.conversationItemTooltip, 'cancel-drag')}
                text={prompt.tooltip}
                config={tooltipConfig}
              >
                <WppPill
                  label={prompt.name}
                  value={prompt.id}
                  checked={prompt.id === activePill}
                  onClick={() => handlePromptClick(prompt)}
                />
              </WppTooltip>
            ) : (
              <WppPill
                key={index}
                label={prompt.name}
                value={prompt.id}
                checked={prompt.id === activePill}
                onClick={() => handlePromptClick(prompt)}
              />
            )
          })}
        </Flex>
      )}

      {/* <WppPillGroup type="single" onWppChange={handlePillGroupChange}> */}

      {/* </WppPillGroup> */}

      {/* <Flex wrap="wrap" gap={8}>
        {activePrompts.map((prompt: PromptsInterface, index: number) => {
          return prompt.tooltip ? (
            <WppTooltip className={clsx(styles.conversationItemTooltip, 'cancel-drag')} text={prompt.tooltip}>
              <div
                key={index}
                className={clsx(styles.exampleCard, 'cancel-drag')}
                onClick={() => onPromptClick(prompt.prompt)}
              >
                <WppTypography type="s-body" tag="p">
                  {prompt.label}
                </WppTypography>
              </div>
            </WppTooltip>
          ) : (
            <div
              key={index}
              className={clsx(styles.exampleCard, 'cancel-drag')}
              onClick={() => onPromptClick(prompt.prompt)}
            >
              <WppTypography type="s-body" tag="p">
                {prompt.label}
              </WppTypography>
            </div>
          )
        })}
      </Flex> */}
    </div>
  )
}
